import InputTime from "../field/InputTime";
import CheckBoxWithTextField from "../field/CheckBoxWithTextField";
import CheckBoxWithDropDown from "../field/CheckBoxWithDropdown";
import CheckBoxMulti from "../field/CheckBoxMulti";
import DatePickerField from "../field/DatePickerField";
import TextField from "../field/TextField";
import { AttendingOptions } from "../../contexts/DataOptions";
import numeral from "numeral";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";
import React, { useContext } from "react";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";
import { subTitleStyle, titleStyle } from "../../hooks/useClassName";
import { format } from "date-fns";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import TimeDropdown from "../field/DropDownPickTime";
import CheckBoxMultiSelectone from "../field/CheckBoxMultiSelectone";

const FormAttempt = ({
  form,
  setField,
  onClickAbortedAttempts,
  number = 0,
  disabled = false,
  edit,
  setValue = false,
}) => {
  console.log("VALIE : ", setValue);
  const { listName } = useContext(ListDoctorAndNurseContext);
  const diff_time =
    form?.room_in &&
    form?.out_of_room &&
    Math.abs(
      new Date().setHours(
        form?.room_in?.split(":")[0],
        form?.room_in?.split(":")[1]
      ) -
        new Date().setHours(
          form?.out_of_room?.split(":")[0],
          form?.out_of_room?.split(":")[1]
        )
    ) / 60000;

  const diff_time2 =
    form?.puncture &&
    form?.line_finalized &&
    Math.abs(
      new Date().setHours(
        form?.puncture?.split(":")[0],
        form?.puncture?.split(":")[1]
      ) -
        new Date().setHours(
          form?.line_finalized?.split(":")[0],
          form?.line_finalized?.split(":")[1]
        )
    ) / 60000;

  const roomin = `Attempt no.${number} start `;
  const roomout = `End attempt no. ${number}`;

  const handleDate = (christDate, buddhistDate) => {
    if (setValue) {
      setValue((prev) => {
        let temp = { ...prev };
        temp["date"] = christDate;
        return { ...temp };
      });
    } else {
      setField("date")(christDate);
    }
  };

  return (
    <div className=" md:px-16 p-5">
      <div className=" md:text-2xl text-xl text-textinput font-semibold mx-10">
        Attempt no.{number} by
      </div>
      <div></div>
      <div className="divide-y divide-light-blue-500">
        <div className="divide-light-blue-500">
          <CheckBoxWithDropDown
            name="attending"
            value={form?.attending}
            title="Attending"
            setField={setField}
            dropDownName="attending_name"
            dropDownValue={form?.attending_name}
            disabled={disabled}
            options={filterRole(listName, "DOCTOR")}
            other_name={"other_attending_name"}
            other_value={form?.other_attending_name}
            setValue={setValue}
            setStateObject={true}
            // options={AttendingOptions}
          />
          <CheckBoxWithTextField
            name="fellow"
            title="Fellow"
            value={form?.fellow}
            setField={setField}
            textFieldName="fellow_name"
            textFieldValue={form?.fellow_name}
            disabled={disabled}
            setValue={setValue}
            setStateObject={true}
          />
          <CheckBoxWithTextField
            name="resident"
            title="Resident"
            value={form?.resident}
            setField={setField}
            textFieldName="resident_name"
            textFieldValue={form?.resident_name}
            disabled={disabled}
            setValue={setValue}
            setStateObject={true}
          />
          <CheckBoxWithTextField
            name="other"
            title="Other"
            value={form?.other}
            setField={setField}
            textFieldName="other_name"
            textFieldValue={form?.other_name}
            disabled={disabled}
            setValue={setValue}
            setStateObject={true}
          />
        </div>
        <CheckBoxMultiSelectone
          name={["rt", "lt"]}
          label={["Rt", "Lt"]}
          value={[form?.rt, form?.lt]}
          setField={setField}
          disabled={disabled}
          setValue={setValue}
          setStateObject={true}
        />
        <CheckBoxMulti
          label={[
            "Upper Extremity",
            "Lower Extremity",
            "Neck",
            "Subclavian",
            "Femoral",
            "Other",
          ]}
          name={["ue", "le", "neck", "subcalvian", "femoral", "other_position"]}
          value={[
            form?.ue,
            form?.le,
            form?.neck,
            form?.subcalvian,
            form?.femoral,
            form?.other_position,
          ]}
          setField={setField}
          other_name="other_position_name"
          other_value={form?.other_position_name}
          disabled={disabled}
          setValue={setValue}
          setStateObject={true}
        />
        <CheckBoxMulti
          title={"ภาวะแทรกซ้อน"}
          label={[
            "Pneumothorax",
            "Hemothorax",
            "Arterial puncture",
            "Paresthesia",
            "Nerve injury",
            "Wire complication",
            // "Limb ischemia related to arterial puncture",
            "Other",
          ]}
          name={[
            "complications_pneumothorax",
            "complications_hemothorax",
            "complications_arterial_puncture",
            "complications_paresthesia",
            "complications_nerve_injury",
            "complications_wire",
            // "complications_limb_ischemia_related_to_arterial_puncture",
            "other_complications",
          ]}
          value={[
            form?.complications_pneumothorax,
            form?.complications_hemothorax,
            form?.complications_arterial_puncture,
            form?.complications_paresthesia,
            form?.complications_nerve_injury,
            form?.complications_wire,
            form?.complications_limb_ischemia_related_to_arterial_puncture,
            form?.other_complications,
          ]}
          setField={setField}
          other_name="other_complications_name"
          other_value={form?.other_complications_name}
          disabled={disabled}
          setValue={setValue}
          setStateObject={true}
        />
        {/* <DatePickerField
          dateType="Date"
          setField={setField}
          name="date"
          title="Date"
          value={form?.date ? form?.date : null}
          disabled={disabled}
          setValue={setValue}
          setStateObject={true}
        /> */}

        <div className="">
          <div className={titleStyle}> Date</div>
          <div
            className="text-base w-52 md:w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  
          mx-auto md:mx-10 lg:mx-10 xl:mx-10"
          >
            <WatDatePicker
              value={form?.date ? form?.date : null}
              onChange={handleDate}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"DD MMMM YYYY"}
              placeholder={"DD/MM/YYYY"}
              inputStyle={{ color: "black", width: "190px" }}
              clearable={true}
              minDate={"1920-01-01"}
              maxDate={dayjs().add(0, "day")}
              disabled={disabled}
              readOnly={false}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 ">
        <div class="text-sm md:grid md:grid-cols-2 md:-mx-16 lg:-mx-8 xl:-mx-8 gap-y-10 ">
          <div className="space-y-2 ">
            <div>
              <TimeDropdown
                setField={setField}
                name="room_in"
                title={roomin}
                classNameTitle="mx-10"
                value={form?.room_in}
                disabled={disabled}
                setValue={setValue}
                setStateObject={true}
              />
            </div>

            <div>
              <TimeDropdown
                setField={setField}
                name="puncture"
                title="Puncture"
                classNameTitle="mx-10"
                value={form?.puncture}
                disabled={disabled}
                setValue={setValue}
                setStateObject={true}
              />
            </div>

            {form?.check_attemp === "สำเร็จ" && (
              <div className="flex flex-col space-y-2  ">
                <div>
                  <TimeDropdown
                    setField={setField}
                    name="line_finalized"
                    title="Line finalized"
                    classNameTitle="mx-10"
                    value={form?.line_finalized}
                    disabled={disabled}
                    setValue={setValue}
                    setStateObject={true}
                  />
                </div>
                <div className=" flex items-center md:gap-10 gap-5  mx-10">
                  Total{" "}
                  {numeral(diff_time2).format("0") > 0
                    ? numeral(diff_time2).format("0")
                    : 0}{" "}
                  mins
                </div>
              </div>
            )}

            <div>
              <TimeDropdown
                setField={setField}
                name="out_of_room"
                title={roomout}
                classNameTitle="mx-10"
                value={form?.out_of_room}
                disabled={disabled}
                setValue={setValue}
                setStateObject={true}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className=" flex items-center md:gap-10 gap-5 mt-9 mx-10">
              Total{" "}
              {numeral(diff_time).format("0") > 0
                ? numeral(diff_time).format("0")
                : 0}{" "}
              mins
            </div>

            <CheckBoxSelectOne
              classNameTitle={"text-sm text-textinput font-bold  mx-10 mt-2"}
              title={"ใส่สายสำเร็จหรือไม่"}
              disabled={disabled}
              setField={setField}
              name="check_attemp"
              value={form?.check_attemp}
              setValue={setValue}
              setStateObject={true}
              label={["สำเร็จ", "ไม่สำเร็จ"]}
              classNameCheckAttemp={
                "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2   md:gap-20  lg:gap-1 xl:gap-1 my-2 mx-10 md:mx-12 lg:mx-10 xl:mx-10"
              }
              classNameCustom="text-sm"
            />
          </div>
        </div>
      </div>

      <TextField
        setField={setField}
        name="note"
        title="Note"
        value={form?.note}
        disabled={disabled}
        setValue={setValue}
        setStateObject={true}
      />

      <div className=" flex justify-center mt-5 gap-5 ">
        {/* <button
            onClick={() => onClickAbortedAttempts("Successful")}
            className=" bg-white border-green-600 border-2 hover:bg-green-600 hover:text-gray-50 hover:shadow-lg shadow-md  w-full p-2 rounded-md flex justify-center text-green-600 font-bold mt-8"
          >
            Success
          </button> */}
        {edit ? (
          <>
            {" "}
            <div className=" text-lg text-red-700 font-semibold my-5">
              *หากแก้ไข attempt เรียบร้อยแล้ว กดปุ่ม Update (ที่อยู่ข้าง Attempt
              no.{number} ) เพื่อบันทึกการแก้ไข
            </div>
          </>
        ) : (
          <>
            {" "}
            {form?.check_attemp === "สำเร็จ" ? (
              <div className={subTitleStyle}>
                *หาก attempt สำเร็จ กดปุ่ม Save
              </div>
            ) : (
              <div disabled={disabled}>
                <div className={subTitleStyle}>
                  *ถ้า attempt ไม่สำเร็จ กด Unsuccessful เพื่อนำไปสู่ attempt
                  ถัดไป
                </div>
                <button
                  disabled={disabled}
                  onClick={() => onClickAbortedAttempts("Unsuccessful")}
                  className=" bg-white border-red-600 border-2 hover:bg-red-600 hover:text-gray-50 hover:shadow-lg shadow-md  w-52 p-2 rounded-md flex justify-center text-red-600 font-bold mt-8 mx-auto"
                >
                  Unsuccessful
                </button>
              </div>
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
};
export default FormAttempt;
