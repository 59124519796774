/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
// worker.js

import axios from "axios";

if ("undefined" === typeof window) {
  importScripts("https://cdn.jsdelivr.net/npm/axios@1.1.2/dist/axios.min.js");
}

const workercode = () => {
  try {
    self.onmessage = async function (e) {
      try {
        // self.importScripts("./testModule"); // eslint-disable-line no-restricted-globals
        // eslint-disable-line no-restricted-globals
        console.log("Message received from main script");
        const { data } = await axios.get(
          `http://localhost:1337/tasks?skip=${
            0 * 25
          }&limit=${25}&report=true&relateFollow=true&relateAttempt=true`
        );
        let tasks = data?.tasks;

        self.postMessage({ tasks });
      } catch (error) {
        console.log("error", error);
      }
    };
  } catch (error) {
    console.log("worker error", error);
  }
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;
